<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('settings.lang_nav_usergroups') "
                :icon="icon"
                :subheading="$t('settings.lang_nav_usergroups')"
                show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <user-group-create-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import UserGroupCreateComponent from "../../../../components/settings/basic/usergroup/UserGroupCreateComponent";


    export default {
        components: {
            UserGroupCreateComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-users icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>